<template>
  <button
    @click.prevent="onPrint(item)"
    class="btn btn--export-filter font-weight-bolder ml-2 mr-3"
  >
    {{ $t("print") }}
  </button>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";
import { UPDATE_TABLE_DATA } from "@/core/services/store/packageCode.js";

export default {
  name: "ActionsComponent",
  props: ["item"],
  methods: {
    onPrint(item = []) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let itemSystemCodes = [item.code];
      let data = { codes: itemSystemCodes };
      ApiService.post(
        "warehouse_management/custom/package_codes/print_barcode",
        data
      )
        .then((response) => {
          this.$store.commit(SET_REPORT_MODAL_DATA, {
            successful_orders_count: response.data.total_successful,
            failed_orders_count: response.data.total_failed,
            successful_orders: response.data.successful_orders,
            failed_orders: response.data.failed_codes,
            message: response.data.message,
            total_orders_count: response.data.total,
          });
          this.$store.commit(SET_REPORT_MODAL_STATE, true);

          if (response.data.total_successful > 0) {
            if (response.data.path) {
              const link = document.createElement("a");
              link.href = response.data.path;
              link.target = "_blank";
              link.download = "orders.csv";
              link.click();
            }
          }
        })
        .then(() => {
          return this.$store.dispatch(UPDATE_TABLE_DATA, {});
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
};
</script>
